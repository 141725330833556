import React, { useEffect, useState } from "react";
const axios = require("axios").default;

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios
      .get("https://general-assignment.parsa-hejabi.workers.dev/")
      .then((res) => {
        setPosts(res.data);
      });
  }, []);

  return (
    <>
      {posts.map((post, index) => (
        <div key={index}>
          <h2>{post.title}</h2>
          <h3>{post.content}</h3>
        </div>
      ))}
    </>
  );
};

export default Posts;
