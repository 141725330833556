import React from "react";
import ReactDOM from "react-dom";
import { Layout, Menu, Typography, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";

import Posts from "./components/posts";

import "antd/dist/antd.css";

const { Header, Content, Footer, Sider } = Layout;
const { Title, Link } = Typography;

ReactDOM.render(
  <React.StrictMode>
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <Menu theme="dark" mode="inline">
          <Menu.Item key="1" icon={<HomeOutlined />}>
            Home
          </Menu.Item>
        </Menu>
        <Button
          type="primary"
          shape="round"
          style={{
            display: "block",
            margin: "auto",
          }}
        >
          Compose
        </Button>
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
          height: "100vh",
        }}
      >
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            backgroundColor: "rgba(0, 21, 41, 0.75)",
            backdropFilter: "blur(12px)",
          }}
        >
          <Typography>
            <Title
              level={3}
              style={{
                color: "rgba(255, 255, 255, 0.85)",
                margin: 0,
                position: "absolute",
                top: "50%",
                transform: "translate(0,-50%)",
                marginLeft: "16px",
              }}
            >
              Home
            </Title>
          </Typography>
        </Header>
        <Content style={{ overflow: "initial" }}>
          <div className="site-layout-background" style={{ padding: 24 }}>
            <Posts />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Created with ❤️ by{" "}
          <Link href="https://parsahejabi.com" target="_blank">
            Parsa Hejabi
          </Link>
        </Footer>
      </Layout>
    </Layout>
  </React.StrictMode>,
  document.getElementById("root")
);
